import { HTMLAttributes, useEffect, useRef, useState } from "react";
import { TempoBaseLine, TempoContainer } from "./Tempo.styled";
import * as d3 from "d3";

import { COLORS } from "../../../../../../../styles";
import { observer } from "mobx-react";
import { useStores } from "../../../../../../../stores";

type TempoProps = HTMLAttributes<HTMLElement>;

export const Tempo = observer(({}: TempoProps) => {
	const { aiStore } = useStores();

	const svgRef = useRef<SVGSVGElement | null>(null);

	useEffect(() => {
		if (svgRef.current) {
			const svg = d3.select(svgRef.current).attr("width", "100%").attr("height", 2); // Высота линии

			const linePath =
				"M 0 1 " + // Начало линии
				"L 100% 1"; // Линия продолжается до 100% ширины контейнера на высоте 1 пиксель

			svg
				.append("path")
				.attr("d", linePath) // Устанавливаем путь линии
				.attr("fill", "none") // Линия не имеет заполнения
				.attr("stroke", "blue") // Цвет линии
				.attr("stroke-width", 2); // Толщина линии
		}
	}, []);

	return (
		<TempoContainer>
			<TempoBaseLine
				key={aiStore.chosenBPM}
				initial={{ width: 0 }} // Начальная ширина
				animate={{ width: "100%" }} // Конечная ширина
				transition={{ duration: 2 }} // Длительность анимации
			/>

			<svg ref={svgRef}></svg>
		</TempoContainer>
	);
});
