import * as React from "react";
import { useEffect } from "react";
import { Button, Divider, InputField, Modal } from "../../../common";
import {
	SignUpDescription,
	SignUpFooter,
	SignUpFormContainer,
	SignUpIcon,
	SignUpList,
	SignUpListItem,
	SignUpModalContainer,
	SignUpModalLeft,
	SignUpModalRight,
	SignUpRightSideFooter,
	SignUpText,
} from "./SignUpModal.styled";
import { ModalHeader } from "../../../common/Modal/Modal.styled";
import { ICON_FORM_1, ICON_FORM_2, ICON_FORM_3 } from "../../../common/Icons";
import { ButtonTheme, NOTIFICATION_TYPE } from "../../../../constants";
import { useFormik } from "formik";
import { AuthSchema } from "../../../../validation-schems";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { AuthSignUpRequest } from "../../../../requests";
import { CommonFormDescription, CommonFormSocial } from "../CommonSignForm/CommonSignForm.styled";
import { gtmEvent } from "../../../../helpers";
import { useNavigate } from "react-router-dom";
// import ReactFacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

//import GoogleLogin from "react-google-login";

interface SignUpModalProps {
	hideLeft?: boolean;
}

export const SignUpModal = observer(({ hideLeft = false }: SignUpModalProps) => {
	const { authStore, notificationsStore, geoPluginStore } = useStores();
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			email: "",
		},
		onSubmit: async (values) => {
			try {
				await authStore.signUp(values as AuthSignUpRequest);

				gtmEvent({ event: "Sign_Up", email: values.email.toLowerCase() });

				notificationsStore.add({
					type: NOTIFICATION_TYPE.SUCCESS,
					title: "Success",
					message: "Please check your email",
				});
			} catch (e: any) {
				notificationsStore.add({
					type: NOTIFICATION_TYPE.ERROR,
					title: "Error",
					message: e.errors.join("<br/>"),
				});
			}
		},
		validationSchema: AuthSchema,
	});

	useEffect(() => {
		if (authStore.signUpForm.modal.isClose) {
			formik.resetForm();
		}
	}, [authStore.signUpForm.modal.isClose]);

	const handleOnSignIn = () => {
		authStore.signUpForm.modal.close();
		authStore.signInForm.modal.open();
	};

	const handleOnFacebook = async (data: any) => {
		try {
			await authStore.signFacebook(data);
			authStore.signUpForm.modal.close();
			navigate("/cabinet/my-subscriptions");

			notificationsStore.add({
				title: "Success",
			});
		} catch {}
	};

	const handleOnGoogle = async (data: any) => {
		try {
			await authStore.signGoogle({
				access_token: data.accessToken,
				email: data.profileObj.email,
				first_name: data.profileObj.givenName,
				last_name: data.profileObj.familyName,
				picture: data.profileObj.imageUrl,
			});
			authStore.signUpForm.modal.close();
			navigate("/cabinet/my-subscriptions");

			gtmEvent({ event: "Sign_up", email: data.profileObj.email.toLowerCase() });
		} catch {}
	};

	return (
		<Modal modalStore={authStore.signUpForm.modal} isShow={authStore.signUpForm.modal.isShow} hideClose={hideLeft}>
			<SignUpModalContainer>
				<SignUpModalLeft>
					<ModalHeader>Start your 14-days free trial</ModalHeader>
					<SignUpList>
						<SignUpListItem>
							<SignUpIcon>
								<img
									src={ICON_FORM_1}
									alt={"Our system is built to guide you step-by-step from 0 to Pro+ level"}
									width={90}
								/>
							</SignUpIcon>
							<SignUpText>Our system is built to guide you step-by-step from 0 to Pro+ level</SignUpText>
						</SignUpListItem>

						<SignUpListItem>
							<SignUpIcon>
								<img
									src={ICON_FORM_2}
									alt={"Intuitive & user-friendly interface, allowing you to enjoy the learning process"}
									width={90}
								/>
							</SignUpIcon>
							<SignUpText>Intuitive & user-friendly interface, allowing you to enjoy the learning process</SignUpText>
						</SignUpListItem>

						<SignUpListItem>
							<SignUpIcon>
								<img
									src={ICON_FORM_3}
									alt={"Study at home or while traveling from your Mac/PC, Tab or Smartphone"}
									width={90}
								/>
							</SignUpIcon>
							<SignUpText>Study at home or while traveling from your Mac/PC, Tab or Smartphone</SignUpText>
						</SignUpListItem>
					</SignUpList>

					<SignUpFooter>Join now & find out how comfortable and convenient our eduction system is</SignUpFooter>
				</SignUpModalLeft>

				<SignUpModalRight>
					<CommonFormSocial>
						{!geoPluginStore.isRussia && (
							<>
								{/*<ReactFacebookLogin
									appId={"514163569493257"}
									callback={handleOnFacebook}
									disableMobileRedirect
									isMobile={false}
									fields="first_name,last_name,email,picture.type(large),birthday,location"
									render={(renderProps) => {
										return (
											<Button theme={ButtonTheme.FACEBOOK} onClick={renderProps.onClick}>
												Log in with Facebook
											</Button>
										);
									}}
								/>*/}
								<Divider>or</Divider>
							</>
						)}

						{/*<GoogleLogin
							clientId={"826618397728-phql2reajkfudj3q0uosjuqb7tpjdlhf.apps.googleusercontent.com"}
							buttonText="Login"
							onSuccess={handleOnGoogle}
							onFailure={(responseError) => console.log("responseError", responseError)}
							cookiePolicy={"single_host_origin"}
							render={(renderProps) => {
								return (
									<Button theme={ButtonTheme.GOOGLE} onClick={renderProps.onClick}>
										Log in with Google
									</Button>
								);
							}}
						/>*/}
					</CommonFormSocial>

					<SignUpFormContainer onSubmit={formik.handleSubmit}>
						<InputField
							type={"email"}
							name={"email"}
							value={formik.values.email}
							placeholder={"Your email"}
							errorMessage={formik.errors.email}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>

						<SignUpDescription style={{ height: "60px" }}>
							Your password will be generated & sent onto your email. You will be able to change it once you login.
						</SignUpDescription>

						<Button type={"submit"} disabled={formik.errors.email !== undefined}>
							Create account
						</Button>
					</SignUpFormContainer>

					<SignUpRightSideFooter>
						<CommonFormDescription>Already have an account?</CommonFormDescription>
						<Button theme={ButtonTheme.LINK_LARGE} onClick={handleOnSignIn}>
							Log In
						</Button>
					</SignUpRightSideFooter>
				</SignUpModalRight>
			</SignUpModalContainer>
		</Modal>
	);
});
