import styled from "styled-components";
import { COLORS, fontMixins } from "../../../../styles";

export const Container = styled.div`
	display: flex;
	align-items: center;
	column-gap: 16px;
	justify-content: end;
	padding-right: 10px;
`;

export const Title = styled.div`
	${fontMixins(16)};
	color: ${COLORS.Dark_Gray_2};
	text-transform: capitalize;
`;

export const ButtonControl = styled.button`
	display: flex;
	margin: 0;
	padding: 0;
	background: transparent;
	border: 0;
	cursor: pointer;
`;

export const PauseButton = styled.span`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	padding: 0;
	margin: 0;
	background: transparent;

	&:before,
	&:after {
		content: "";
		position: absolute;
		top: 7px;
		width: 2px;
		height: 12px;
	}

	&:before {
		margin-right: 5px;
	}

	&:after {
		margin-left: 5px;
	}
`;
