import * as React from "react";
import { MainContainer } from "./Main.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";

interface MainProps {
	children: React.ReactNode;
}

export const Main = observer(({ children }: MainProps) => {
	const { settingsStore } = useStores();

	return <MainContainer isWhite={settingsStore.isWhiteMain}>{children}</MainContainer>;
});
