import { Button, Container, StopButton } from "./SampleButton.styled";
import { ReactComponent as PlayIcon } from "./images/play.svg";
import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { useStores } from "../../../../../../stores";
import { Midi } from "@tonejs/midi";
import * as Tone from "tone";

interface SampleButtonProps {}

export const SampleButton = observer(({}: SampleButtonProps) => {
	const { viewerStore, aiStore } = useStores();
	const { chooseItem } = viewerStore;

	const [isPlay, setIsPlay] = useState(false);

	// Инстанс Midi Player
	const midiPlayer = useRef<Tone.Part | null>(null);

	// Инстанс диланны midi дорожки
	const midiDurationRef = useRef<number>(0);

	// Загрузка MIDI
	useEffect(() => {
		(async () => {
			//if (midiPlayer.current === null) {
			const fullPathAudio = chooseItem.ai_midi.fullPathAudio;

			if (!fullPathAudio) {
				return;
			}

			console.log(1, aiStore.chosenBPM);

			// Загружаем MIDI
			const responseMidi = await fetch(fullPathAudio);
			const arrayBuffer = await responseMidi.arrayBuffer();

			// Обрабатываем
			const midiData = new Midi(arrayBuffer);

			// Вычисляем длительность MIDI-файла
			//midiDurationRef.current = calculateMidiDuration(midiData);

			console.log(2, aiStore.chosenBPM);

			// Текущая громкость
			const gain = new Tone.Gain(-0.3).toDestination();

			// Поулчаем MIDI
			const notes = midiData.tracks[0].notes.length > 0 ? midiData.tracks[0].notes : midiData.tracks[1].notes;

			// Устанавливаем темп в Tone.js
			//Tone.Transport.bpm.value = 60;

			midiPlayer.current = new Tone.Part((time, note) => {
				// Название ноты
				// const noteName = note.name;

				const synth = new Tone.Synth().connect(gain);
				synth.triggerAttackRelease(note.name, note.duration, time, note.velocity);
			}, notes);

			const endTime = notes.reduce((maxTime, note) => {
				return Math.max(maxTime, note.time + note.duration);
			}, 0);

			midiPlayer.current.loop = false; // Устанавливаем, если нужно
			midiPlayer.current.humanize = true; // Можно включить humanize для более естественного звучания
			midiPlayer.current.start(0); // Стартуем сразу, чтобы синхронизировать

			// Устанавливаем темп в Tone.js
			//Tone.Transport.bpm.value = aiStore.chosenBPM;
			//}

			Tone.Transport.on("start", () => console.log("Tone.Transport start"));
			Tone.Transport.on("stop", () => console.log("Tone.Transport stop"));
			Tone.Transport.on("pause", () => console.log("Tone.Transport pause"));
			Tone.Transport.on("loop", () => console.log("Tone.Transport loop"));
			Tone.Transport.on("loopEnd", () => console.log("Tone.Transport loopEnd"));
			Tone.Transport.on("loopStart", () => console.log("Tone.Transport loopStart"));

			//Tone.Transport.scheduleOnce(() => {}, midiPlayer.current?.duration);

			console.log(midiPlayer?.current);

			//midiPlayer.current;
		})();

		console.log("change midi ", chooseItem.ai_midi.fullPathAudio);

		return () => {
			// Очистить или остановить при размонтировании компонента
			if (midiPlayer.current) {
				midiPlayer.current.stop();
			}
		};
	}, [chooseItem.ai_midi.fullPathAudio, aiStore.chosenBPM]);

	const calculateMidiDuration = (midi: Midi): number => {
		let maxTime = 0;
		midi.tracks.forEach((track) => {
			track.notes.forEach((note) => {
				const noteEndTime = note.time + note.duration;
				if (noteEndTime > maxTime) {
					maxTime = noteEndTime;
				}
			});
		});
		return maxTime;
	};

	const handleOnPlay = async () => {
		setIsPlay((prevState) => {
			const isPlay = prevState;

			(async () => {
				if (midiPlayer.current instanceof Tone.Part) {
					if (!isPlay) {
						// Убедитесь, что аудио-контекст готов
						await Tone.start();
						// Устанавливаем темп в Tone.js
						Tone.Transport.bpm.value = aiStore.chosenBPM;

						// Устанавливаем точку
						Tone.Transport.start();
						midiPlayer.current.start();

						console.log("start with BPM : ", Tone.Transport.bpm);
						console.log("Current instance Tone.Transport : ", Tone.Transport);
					} else {
						// Устанавливаем темп в Tone.js
						Tone.Transport.cancel(0);
						Tone.Transport.stop();
						midiPlayer.current.stop(0);
					}

					/*if (!isPlay) {
						console.log("start");
						// Устанавливаем темп в Tone.js
						Tone.Transport.bpm.value = aiStore.chosenBPM;

						Tone.start(); // Необходимо для активации аудио-системы браузера
						Tone.Transport.start();
						midiPlayer.current.start();
						console.log("start with BPM : ", Tone.Transport.bpm);

						console.log("Current instance Tone.Transport : ", Tone.Transport);
					} else {
						// Устанавливаем темп в Tone.js
						Tone.Transport.cancel(0);
						Tone.Transport.stop();
						midiPlayer.current.stop(0);
					}*/
				}
			})();

			return !isPlay;
		});
	};

	console.debug("re-render parent");
	return (
		<Container key={"stable"}>
			<Button onClick={handleOnPlay}>
				<span>
					{!isPlay && <PlayIcon />} {isPlay && <StopButton />}
				</span>
				Sample
			</Button>
		</Container>
	);
});
