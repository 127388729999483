import { Container } from "./BpmList.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../../../stores";
import { AiBpm } from "../../../../../../constants";
import { ButtonBpm } from "./ButtonBpm";
import { useEffect } from "react";
import { getDataFromStorageByItemId, setBpmByItemId } from "../../../../../../helpers";

interface BpmListProps {}

export const BpmList = observer(({}: BpmListProps) => {
	const { aiStore, viewerStore } = useStores();

	// Если обновили ноты / чарт
	useEffect(() => {
		// Проверяем в памяти, есть ли предыдущие настройки
		const data = getDataFromStorageByItemId(viewerStore.chooseItem.id);

		if (data) {
			aiStore.setChooseBPM(data.bpm);
		} else {
			aiStore.setChooseBPM(AiBpm.Bpm60);
		}
	}, [viewerStore.chooseItem.id]);

	// Смена BPM
	useEffect(() => {
		// Запоминаем BPM
		setBpmByItemId(viewerStore.chooseItem.id, aiStore.chosenBPM);
	}, [aiStore.chosenBPM]);

	return (
		<Container>
			{Object.values(AiBpm)
				.filter((x) => typeof x === "number")
				.map((bpm, index) => (
					<ButtonBpm key={bpm} bpm={bpm as AiBpm} count={index + 1} isActive={bpm === aiStore.chosenBPM} />
				))}
		</Container>
	);
});
