import { action, computed, observable } from "mobx";
import { AiAccumultorResult, AiResultNull, NumberNull, ScoreItemAiMidiResult, StringNull } from "../../interfaces";
import { CrudStore } from "../common/crud.store";
import { INSTRUMENT_NAME, ScoreItemAiMidiBpm, ScoreItemAiMidiStatus } from "../../constants";
import { API } from "../../core";

export class ScoreItemAiMidiResultStore extends CrudStore<ScoreItemAiMidiResult> implements ScoreItemAiMidiResult {
	PATH = "user-result-ais";

	@observable user_id = 0;
	@observable lesson_uuid = "";
	@observable score_item_id: NumberNull = 0;
	@observable score_item_ai_midi_id: NumberNull = 0;
	@observable chart_item_id: NumberNull = 0;
	@observable chart_item_ai_midi_id: NumberNull = 0;
	@observable user_track: StringNull = "";
	@observable bpm = ScoreItemAiMidiBpm.Bpm60;
	@observable instrument = INSTRUMENT_NAME.GUITAR;
	@observable result: AiResultNull = null;
	@observable tempo_match_score = 0;
	@observable tempo_stand_score = 0;
	@observable notes_match_score = 0;
	@observable count_attempts = 0;
	@observable status = ScoreItemAiMidiStatus.Create;
	@observable is_view = false;

	constructor(initialData: ScoreItemAiMidiResult | null) {
		super();
		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	changeStatus(value: ScoreItemAiMidiStatus) {
		this.status = value;
	}

	@action.bound
	clearUserTrack() {
		this.user_track = null;
	}

	mergeResultNotes(time = 20): AiAccumultorResult {
		return computed(() => {
			if (!this.result) {
				return [];
			}

			let accumulator: AiAccumultorResult = [];
			const countElement = this.result.found_notes.notes.length;

			for (let i = 0; i < countElement; i++) {
				// Отступ от нот
				let marginLeft = 0;
				let marginLeftPercent = 0;
				let width = this.result.found_notes.end[i] - this.result.found_notes.start[i];
				width = parseFloat(((width * 100) / time).toFixed(2));

				if (i === 0 && this.result.found_notes.start[i] !== 0) {
					marginLeftPercent = parseFloat(((this.result.found_notes.start[i] * 100) / time).toFixed(2));
				} else if (i > 0) {
					if (accumulator.length > 0) {
						// Получаем предыдущий элемент
						marginLeft = parseFloat((this.result.found_notes.start[i] - this.result.found_notes.end[i - 1]).toFixed(2));

						if (marginLeft > 0) {
							marginLeftPercent = parseFloat(((marginLeft * 100) / time).toFixed(2));
						}
					}
				}

				accumulator.push([
					this.result.found_notes.notes[i],
					this.result.found_notes.start[i],
					this.result.found_notes.end[i],
					marginLeftPercent,
					width,
				]);
			}

			return accumulator;
		}).get();
	}

	@computed
	get fullPathUserTrack() {
		return `${API.getFilesPath}${this.user_track}`;
	}

	@computed
	get totalResultPercent(): number {
		return parseFloat(
			(
				((this.tempo_match_score * 100 + this.tempo_stand_score * 100 + this.notes_match_score * 100) / 300) *
				100
			).toFixed(0),
		);
	}

	@computed
	get tempoMatchScorePercent() {
		return (this.tempo_match_score * 100).toFixed(0);
	}

	@computed
	get tempoStandScorePercent() {
		return (this.tempo_stand_score * 100).toFixed(0);
	}

	@computed
	get notesMatchScorePercent() {
		return (this.notes_match_score * 100).toFixed(0);
	}

	@action
	fillStore(data: ScoreItemAiMidiResult) {
		const {
			id,
			user_id,
			lesson_uuid,
			score_item_id,
			score_item_ai_midi_id,
			chart_item_id,
			chart_item_ai_midi_id,
			user_track,
			bpm,
			instrument,
			result,
			tempo_match_score,
			tempo_stand_score,
			notes_match_score,
			count_attempts,
			status,
			is_view,
		} = data;

		this.id = id;
		this.user_id = user_id;
		this.lesson_uuid = lesson_uuid;
		this.score_item_id = score_item_id;
		this.score_item_ai_midi_id = score_item_ai_midi_id;
		this.chart_item_id = chart_item_id;
		this.chart_item_ai_midi_id = chart_item_ai_midi_id;
		this.user_track = user_track;
		this.bpm = bpm;
		this.instrument = instrument;
		this.result = result;
		this.tempo_match_score = tempo_match_score;
		this.tempo_stand_score = tempo_stand_score;
		this.notes_match_score = notes_match_score;
		this.count_attempts = count_attempts;
		this.status = status;
		this.is_view = is_view;
	}
}
