import * as React from "react";
import { FormEvent } from "react";
import {
	CommonFormContainer,
	CommonFormDescription,
	CommonFormRightSide,
	CommonFormRightSideFooter,
	CommonFormSocial,
	CommonFormWrapper,
} from "../../Modals/CommonSignForm/CommonSignForm.styled";
import { Button, InputField } from "../../../common";
import { ButtonTheme, NOTIFICATION_TYPE, SIGN_PAGE } from "../../../../constants";
//import GoogleLogin from "react-google-login";
import { SignUpDescription } from "../../Modals/SignUpModal/SignUpModal.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { gtmEvent } from "../../../../helpers";
import { AuthSchema } from "../../../../validation-schems";
import { FacebookClientResponsive } from "../../../../response";

interface SignInFormProps {
	onChangeForm: (value: SIGN_PAGE) => void;
}

export const SignInForm = observer(({ onChangeForm }: SignInFormProps) => {
	const { authStore, notificationsStore, geoPluginStore } = useStores();
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		onSubmit: async (values) => {
			try {
				await authStore.signIn(values);
				authStore.signInForm.modal.close();
				navigate("/cabinet/my-day");

				notificationsStore.add({
					title: "Success",
				});

				gtmEvent({ event: "Sign_in", email: values.email.toLowerCase() });

				gtmEvent({ event: "User_ID", email: authStore.userStore.id });
			} catch (e: any) {
				notificationsStore.add({
					type: NOTIFICATION_TYPE.ERROR,
					title: "Error",
					message: e.errors.join("<br/>"),
				});
			}
		},
		validationSchema: AuthSchema,
	});

	const handleOnForgoPassword = (e: FormEvent) => {
		e.preventDefault();
		onChangeForm(SIGN_PAGE.RECOVER);
	};

	const handleOnSignUp = (e: FormEvent) => {
		e.preventDefault();
		onChangeForm(SIGN_PAGE.SIGN_UP);
	};

	const handleOnFacebook = async (data: FacebookClientResponsive) => {
		try {
			await authStore.signFacebook(data);
			authStore.signInForm.modal.close();
			navigate("/cabinet/my-day");

			notificationsStore.add({
				title: "Success",
			});
		} catch {}
	};

	const handleOnGoogle = async (data: any) => {
		try {
			await authStore.signGoogle({
				access_token: data.accessToken,
				email: data.profileObj.email,
				first_name: data.profileObj.givenName,
				last_name: data.profileObj.familyName,
				picture: data.profileObj.imageUrl,
			});
			authStore.signInForm.modal.close();
			navigate("/cabinet/my-day");

			gtmEvent({ event: "Sign_in", email: data.profileObj.email.toLowerCase() });
		} catch {}
	};

	return (
		<CommonFormContainer onlyForm>
			<CommonFormRightSide>
				<CommonFormSocial>
					{/*<GoogleLogin
						clientId={"826618397728-phql2reajkfudj3q0uosjuqb7tpjdlhf.apps.googleusercontent.com"}
						buttonText="Login"
						onSuccess={handleOnGoogle}
						onFailure={(responseError) => console.log("responseError", responseError)}
						cookiePolicy={"single_host_origin"}
						render={(renderProps) => {
							return (
								<Button theme={ButtonTheme.GOOGLE} onClick={renderProps.onClick}>
									Sign in with Google
								</Button>
							);
						}}
					/>*/}
				</CommonFormSocial>

				<SignUpDescription style={{ height: "30px" }}>or log in with email</SignUpDescription>

				<CommonFormWrapper
					onSubmit={(e) => {
						formik.handleSubmit(e);
					}}
					onKeyDown={(e) => {
						e.stopPropagation();

						if (e.key === "Enter") {
							e.preventDefault();
							formik.handleSubmit();
						}
					}}
				>
					<InputField
						type={"email"}
						name={"email"}
						value={formik.values.email}
						placeholder={"Your email"}
						errorMessage={formik.errors.email}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>

					<InputField
						type={"password"}
						name={"password"}
						value={formik.values.password}
						placeholder={"Your password"}
						errorMessage={formik.errors.password}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>

					<Button theme={ButtonTheme.LINK} onClick={handleOnForgoPassword}>
						Forgot your password?
					</Button>
					<Button type={"submit"} disabled={formik.errors.email !== undefined}>
						Sign in
					</Button>
				</CommonFormWrapper>

				<CommonFormRightSideFooter>
					<CommonFormDescription>Don't have an account?</CommonFormDescription>
					<Button theme={ButtonTheme.LINK_LARGE} onClick={handleOnSignUp}>
						Sign Up
					</Button>
				</CommonFormRightSideFooter>
			</CommonFormRightSide>
		</CommonFormContainer>
	);
});
