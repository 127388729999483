import styled from "styled-components";

interface ContainerGridBlock {
	width?: number;
}

export const ContainerGridBlock = styled.div<ContainerGridBlock>`
	display: grid;
	grid-template-columns: 1fr;
	width: ${(props) => (props.width ? `${props.width}px` : `980px`)};
	margin: 0 auto;
`;
