import styled from "styled-components";
import { COLORS, fontMixins } from "../../../../../../../styles";
import { motion } from "framer-motion";

export const Container = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 330px;
	display: flex;
`;

export const Line = styled(motion.div)`
	position: relative;
	display: flex;
	height: 100%;
	border-right: 1px dashed ${COLORS.Dark_Gray};
`;

export const LineNote = styled.div`
	position: absolute;
	left: 5px;
	top: 10px;
	${fontMixins(10)};
	color: ${COLORS.Dark_Gray};
`;
