import styled from "styled-components";
import { fontMixins } from "../../../styles";

export const TContainer = styled.div`
	display: flex;
	flex-direction: column;
	${fontMixins(40, 700)};
	margin-bottom: 23px;
	align-items: center;
`;

export const DiscountBlock = styled.div`
	display: flex;
	justify-content: center;
	margin-block: 23px 47px;
	${fontMixins(16, 300)};
	color: #606060;
`;
