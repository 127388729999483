import { DiscountBlock, TContainer } from "./Title.styled";

interface TitleProps {}

export const Title = ({}: TitleProps) => {
	return (
		<TContainer>
			Get up to 75% OFF all plans!
			<DiscountBlock>Discount automatically applied at checkout</DiscountBlock>
		</TContainer>
	);
};
