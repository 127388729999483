import * as React from "react";
import { useEffect } from "react";
import {
	ContentSide,
	ViewerAudioPlayer,
	ViewerBody,
	ViewerContainer,
	ViewerLeftSide,
	ViewerMetronome,
	ViewerRightSide,
} from "./Viewer.styled";
import { useStores } from "../../../../stores";
import { ModalPortal } from "../../../common";
import { observer } from "mobx-react";
import { AudioPlayer } from "../../../../pages/LessonPage/Header/AudioPlayer/AudioPlayer";
import { Metronome } from "../../../../pages/LessonPage/Header/Metronome/Metronome";
import { ScoreItemAiMidiStatus, ServiceName } from "../../../../constants";
import { AI } from "./AI/AI";
import { SidebarAI } from "./SidebarAI";

interface ViewerProps {
	theme: ServiceName;
}

export const ViewerModal = observer(({ theme }: ViewerProps) => {
	const { viewerStore, aiStore, websocketStore } = useStores();
	const { lessonStore, audioPlayerStore } = useStores();

	// При открытии окна
	useEffect(() => {
		if (viewerStore.modal.isShow && aiStore.chosenBPM) {
			// Проверяем есть ли результаты
			if (viewerStore.chooseItem) {
				const resultAI = viewerStore.chooseItem.getAiResultByBpm(aiStore.chosenBPM);

				console.log(resultAI?.is_view, resultAI?.status);

				// Если не просмотрено то открываем
				if (resultAI && !resultAI.is_view && [ScoreItemAiMidiStatus.Finish].includes(resultAI.status)) {
					aiStore.resultModal.open();
				}
			}
		}
	}, [viewerStore.modal.isShow, viewerStore.chooseItem, aiStore.chosenBPM]);

	// При закрытии окна
	useEffect(() => {
		(async () => {
			if (!viewerStore.modal.isShow) {
				lessonStore.scores.currentScore.setViewScoreIndex(null);
				lessonStore.scores.currentScore.setIsView(false);

				// Останавливаем трек
				audioPlayerStore.onStop();
			}
		})();
	}, [viewerStore.modal.isShow]);

	useEffect(() => {
		window.addEventListener("keyup", handleCloseByEsc);

		return () => {
			window.removeEventListener("keyup", handleCloseByEsc);
		};
	}, []);

	const handleCloseByEsc = (e: KeyboardEvent) => {
		if (e.key === "Escape") {
			viewerStore.modal.close();
		}
	};

	const handleOnChangeChoose = async (id: number, indexItem: number) => {
		viewerStore.setChooseItemId(id);

		let findItem;

		if (lessonStore.currentService === ServiceName.College) {
			findItem = lessonStore.charts.currentChart?.items.find((item) => item.id === id);
		} else {
			findItem = lessonStore.scores.currentScore?.items.find((item) => item.id === id);
		}

		if (findItem) {
			const library = findItem.libraries[0];

			if (library) {
				audioPlayerStore.setSelectedLibrary(library);
			} else {
				audioPlayerStore.clearSelectedLibrary();
			}
		}

		await audioPlayerStore.loadTrack();
	};

	return (
		<>
			<ModalPortal modalStore={viewerStore.modal} transparent center>
				<ViewerContainer theme={theme}>
					<ViewerAudioPlayer>
						<AudioPlayer isView />
					</ViewerAudioPlayer>

					<ViewerMetronome>
						<Metronome isHorizontal />
					</ViewerMetronome>

					<ViewerBody>
						<ContentSide>
							<ViewerLeftSide>
								<ul>
									{viewerStore.filteredList.map((item, numberItem) => {
										return (
											<li key={item.id} className={`${item.id === viewerStore.chooseItemID ? `active` : ""}`}>
												<button
													onClick={async () => {
														await handleOnChangeChoose(item.id, numberItem);
														aiStore.clearRecord();
													}}
												>
													{numberItem + 1}
												</button>
											</li>
										);
									})}
								</ul>
							</ViewerLeftSide>

							<ViewerRightSide center={!aiStore.recordNow}>
								<picture>
									<img src={viewerStore.image} draggable={false} alt={""} />
								</picture>

								{/** Показываем блок с записью, когда пользователь начал запись */}
								{(aiStore.recordNow ||
									aiStore.existRecord ||
									viewerStore.chooseItem.getAiResultByBpm(aiStore.chosenBPM)) && <AI />}
							</ViewerRightSide>
						</ContentSide>

						<SidebarAI />
					</ViewerBody>
				</ViewerContainer>
			</ModalPortal>
		</>
	);
});
