import { Container, WrapperStarIcon } from "./Ratings.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../../../../../stores";
import { AiBpm } from "../../../../../../../../constants";

interface RatingsProps {
	count: number;
	bpm: AiBpm;
}

export const Ratings = observer(({ count, bpm }: RatingsProps) => {
	const { viewerStore } = useStores();

	const resultAI = viewerStore.chooseItem.getAiResultByBpm(bpm);

	return (
		<Container>
			{[...Array(count).keys()].map((index) => {
				let isGold = false;

				if (resultAI) {
					switch (index) {
						case 0: {
							isGold = resultAI.totalResultPercent > 20;
							break;
						}
						case 1: {
							isGold = resultAI.totalResultPercent > 40;
							break;
						}
						case 2: {
							isGold = resultAI.totalResultPercent > 60;
							break;
						}
						case 3: {
							isGold = resultAI.totalResultPercent > 80;
							break;
						}
						case 4: {
							isGold = resultAI.totalResultPercent > 85;
							break;
						}
					}
				}

				return <WrapperStarIcon key={index} isGold={isGold} />;
			})}
		</Container>
	);
});
