import * as React from "react";
import {
	Actions,
	Container,
	LearningDescription,
	LearningIcon,
	LearningList,
	LearningListItem,
	LearningTitle,
	WhiteContainer,
} from "./WhatIsMusicabinet.styled";
import { ButtonStartTrial, Description, Title } from "../../../components/common";
import { ReactComponent as OneIcon } from "./LearningList/one.svg";
import { ReactComponent as TwoIcon } from "./LearningList/two.svg";
import { ReactComponent as ThreeIcon } from "./LearningList/three.svg";
import { VideoBlock } from "./VideoBlock/VideoBlock";
import { ServiceList } from "./ServiceList/ServiceList";
import { TitleDivider } from "../../../components/common/Title/Title.styled";
import { InstrumentList } from "./InstrumentList/InstrumentList";
import { SubTitle } from "../MainPage.styled";

export const WhatIsMusicabinet = () => {
	return (
		<WhiteContainer>
			<Container>
				<Title>What is MUSICABINET?</Title>
				<TitleDivider />
				<SubTitle>
					MUSICABINET is the Largest and the most Structured
					<br />
					Music Education Platform in the World
				</SubTitle>
				<Description size={"small"}>
					We are here to offer you the most convenient & comfortable music education
				</Description>

				<LearningList>
					<LearningListItem>
						<LearningIcon>
							<OneIcon />
						</LearningIcon>

						<LearningTitle>Choose</LearningTitle>
						<LearningDescription>
							Select the instrument
							<br /> & level of education you want
						</LearningDescription>
					</LearningListItem>

					<LearningListItem>
						<LearningIcon>
							<TwoIcon />
						</LearningIcon>

						<LearningTitle>Start</LearningTitle>
						<LearningDescription>
							Starting your professional music
							<br /> training is easy as 1, 2, 3
						</LearningDescription>
					</LearningListItem>

					<LearningListItem>
						<LearningIcon>
							<ThreeIcon />
						</LearningIcon>

						<LearningTitle>Play</LearningTitle>
						<LearningDescription>
							At the end of each course your playing skills will take a tremendous step up
						</LearningDescription>
					</LearningListItem>
				</LearningList>

				<VideoBlock />

				<Title>Choose your instrument</Title>
				<TitleDivider />
				<Description>
					Which one do you want to perfect? Our education system will guide you through the process and let you master
					any of these instruments.
				</Description>

				<InstrumentList />

				<Title>Each instrument has 3 levels of difficulty</Title>
				<TitleDivider />
				<Description>
					Each one of them has its special unque methods. If you are not sure you can take a test to find where we
					recommend you to start
				</Description>
				<ServiceList />

				<Actions>
					<ButtonStartTrial />
				</Actions>
			</Container>
		</WhiteContainer>
	);
};
