import * as React from "react";
import { FormEvent, useEffect } from "react";
import { Button, CustomModal, Divider, InputField } from "../../../common";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import {
	CommonFormContainer,
	CommonFormDescription,
	CommonFormIcon,
	CommonFormLeftSide,
	CommonFormLeftSideFooter,
	CommonFormList,
	CommonFormListItem,
	CommonFormRightSide,
	CommonFormRightSideFooter,
	CommonFormSocial,
	CommonFormText,
	CommonFormWrapper,
} from "../CommonSignForm/CommonSignForm.styled";
import { ModalHeader } from "../../../common/Modal/Modal.styled";
import { ICON_FORM_1, ICON_FORM_2, ICON_FORM_3 } from "../../../common/Icons";
import { ButtonTheme, NOTIFICATION_TYPE } from "../../../../constants";
import { SignUpDescription } from "../SignUpModal/SignUpModal.styled";
import { useFormik } from "formik";
import { AuthSchema } from "../../../../validation-schems";
import { useNavigate } from "react-router-dom";
// import ReactFacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { FacebookClientResponsive } from "../../../../response";
//import GoogleLogin from "react-google-login";
import { gtmEvent } from "../../../../helpers";

export const SignInModal = observer(() => {
	const { authStore, notificationsStore, geoPluginStore } = useStores();
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		onSubmit: async (values) => {
			try {
				await authStore.signIn(values);
				authStore.signInForm.modal.close();
				navigate("/cabinet/my-subscriptions");

				notificationsStore.add({
					title: "Success",
				});

				gtmEvent({ event: "Sign_in", email: values.email.toLowerCase() });
			} catch (e: any) {
				notificationsStore.add({
					type: NOTIFICATION_TYPE.ERROR,
					title: "Error",
					message: e.errors.join("<br/>"),
				});
			}
		},
		validationSchema: AuthSchema,
	});

	useEffect(() => {
		if (authStore.signInForm.modal.isClose) {
			formik.resetForm();
		}
	}, [authStore.signInForm.modal.isClose]);

	const handleOnForgoPassword = (e: FormEvent) => {
		e.preventDefault();
		authStore.signInForm.modal.close();
		authStore.forgotForm.modal.open();
	};

	const handleOnSignUp = (e: FormEvent) => {
		e.preventDefault();
		authStore.signInForm.modal.close();
		authStore.signUpForm.modal.open();
	};

	const handleOnFacebook = async (data: FacebookClientResponsive) => {
		try {
			await authStore.signFacebook(data);
			authStore.signInForm.modal.close();
			navigate("/cabinet/my-subscriptions");

			notificationsStore.add({
				title: "Success",
			});
		} catch {}
	};

	const handleOnGoogle = async (data: any) => {
		try {
			await authStore.signGoogle({
				access_token: data.accessToken,
				email: data.profileObj.email,
				first_name: data.profileObj.givenName,
				last_name: data.profileObj.familyName,
				picture: data.profileObj.imageUrl,
			});
			authStore.signInForm.modal.close();
			navigate("/cabinet/my-subscriptions");

			gtmEvent({ event: "Sign_in", email: data.profileObj.email.toLowerCase() });
		} catch {}
	};

	return (
		<CustomModal isOpen={authStore.signInForm.modal.isShow} modalStore={authStore.signInForm.modal}>
			<CommonFormContainer>
				<CommonFormLeftSide>
					<ModalHeader>Start your 14-days free trial</ModalHeader>
					<CommonFormList>
						<CommonFormListItem>
							<CommonFormIcon>
								<img
									src={ICON_FORM_1}
									alt={"Our system is built to guide you step-by-step from 0 to Pro+ level"}
									width={90}
								/>
							</CommonFormIcon>
							<CommonFormText>Our system is built to guide you step-by-step from 0 to Pro+ level</CommonFormText>
						</CommonFormListItem>

						<CommonFormListItem>
							<CommonFormIcon>
								<img
									src={ICON_FORM_2}
									alt={"Intuitive & user-friendly interface, allowing you to enjoy the learning process"}
									width={90}
								/>
							</CommonFormIcon>
							<CommonFormText>
								Intuitive & user-friendly interface, allowing you to enjoy the learning process
							</CommonFormText>
						</CommonFormListItem>

						<CommonFormListItem>
							<CommonFormIcon>
								<img
									src={ICON_FORM_3}
									alt={"Study at home or while traveling from your Mac/PC, Tab or Smartphone"}
									width={90}
								/>
							</CommonFormIcon>
							<CommonFormText>Study at home or while traveling from your Mac/PC, Tab or Smartphone</CommonFormText>
						</CommonFormListItem>
					</CommonFormList>

					<CommonFormLeftSideFooter>
						Join now & find out how comfortable and convenient our eduction system is
					</CommonFormLeftSideFooter>
				</CommonFormLeftSide>

				<CommonFormRightSide>
					<CommonFormSocial>
						{!geoPluginStore.isRussia && (
							<>
								{/*<ReactFacebookLogin
									appId={"514163569493257"}
									callback={handleOnFacebook}
									disableMobileRedirect
									isMobile={false}
									fields="first_name,last_name,email,picture.type(large),birthday,location"
									render={(renderProps) => {
										return (
											<Button theme={ButtonTheme.FACEBOOK} onClick={renderProps.onClick}>
												Log in with Facebook
											</Button>
										);
									}}
								/>*/}
								<Divider>or</Divider>
							</>
						)}

						{/*<GoogleLogin
							clientId={"826618397728-phql2reajkfudj3q0uosjuqb7tpjdlhf.apps.googleusercontent.com"}
							buttonText="Login"
							onSuccess={handleOnGoogle}
							onFailure={(responseError) => console.log("responseError", responseError)}
							cookiePolicy={"single_host_origin"}
							render={(renderProps) => {
								return (
									<Button theme={ButtonTheme.GOOGLE} onClick={renderProps.onClick}>
										Log in with Google
									</Button>
								);
							}}
						/>*/}
					</CommonFormSocial>

					<SignUpDescription style={{ height: "30px" }}>or log in with email</SignUpDescription>

					<CommonFormWrapper
						onSubmit={formik.handleSubmit}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								e.preventDefault();
								formik.handleSubmit();
							}
						}}
					>
						<InputField
							type={"email"}
							name={"email"}
							value={formik.values.email}
							placeholder={"Your email"}
							errorMessage={formik.errors.email}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>

						<InputField
							type={"password"}
							name={"password"}
							value={formik.values.password}
							placeholder={"Your password"}
							errorMessage={formik.errors.password}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>

						<Button theme={ButtonTheme.LINK} onClick={handleOnForgoPassword}>
							Forgot your password?
						</Button>
						<Button type={"submit"} disabled={formik.errors.email !== undefined}>
							Log in
						</Button>
					</CommonFormWrapper>

					<CommonFormRightSideFooter>
						<CommonFormDescription>Don't have an account?</CommonFormDescription>
						<Button theme={ButtonTheme.LINK_LARGE} onClick={handleOnSignUp}>
							Sign Up
						</Button>
					</CommonFormRightSideFooter>
				</CommonFormRightSide>
			</CommonFormContainer>
		</CustomModal>
	);
});
