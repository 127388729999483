import * as React from "react";
import { FormEvent } from "react";
import {
	CommonFormContainer,
	CommonFormDescription,
	CommonFormRightSide,
	CommonFormSocial,
} from "../../Modals/CommonSignForm/CommonSignForm.styled";
import { Button, InputField } from "../../../common";
import { ButtonTheme, NOTIFICATION_TYPE, SIGN_PAGE } from "../../../../constants";
import {
	SignUpDescription,
	SignUpFormContainer,
	SignUpRightSideFooter,
} from "../../Modals/SignUpModal/SignUpModal.styled";
import { useStores } from "../../../../stores";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { gtmEvent } from "../../../../helpers";
import { AuthSchema } from "../../../../validation-schems";
import { FacebookClientResponsive } from "../../../../response";

import { AuthSignUpRequest } from "../../../../requests";

interface SignIUpFormProps {
	onChangeForm: (value: SIGN_PAGE) => void;
}

export const SignUpForm = ({ onChangeForm }: SignIUpFormProps) => {
	const { authStore, notificationsStore, geoPluginStore } = useStores();
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			email: "",
		},
		onSubmit: async (values) => {
			try {
				await authStore.signUp(values as AuthSignUpRequest);

				gtmEvent({ event: "Sign_Up", email: values.email.toLowerCase() });

				// Уведомление
				authStore.setCongratulationsEmail(values.email);
				authStore.congratulationsModal.open();

				// Очищаем форму
				formik.resetForm();
			} catch (e: any) {
				notificationsStore.add({
					type: NOTIFICATION_TYPE.ERROR,
					title: "Error",
					message: e.errors.join("<br/>"),
				});
			}
		},
		validationSchema: AuthSchema,
	});

	const handleOnForgoPassword = (e: FormEvent) => {
		e.preventDefault();
		onChangeForm(SIGN_PAGE.RECOVER);
	};

	const handleOnSignIn = (e: FormEvent) => {
		e.preventDefault();
		onChangeForm(SIGN_PAGE.SIGN_IN);
	};

	const handleOnFacebook = async (data: FacebookClientResponsive) => {
		try {
			await authStore.signFacebook(data);
			authStore.signInForm.modal.close();
			navigate("/cabinet/my-subscriptions");

			notificationsStore.add({
				title: "Success",
			});
		} catch {}
	};

	const handleOnGoogle = async (data: any) => {
		try {
			await authStore.signGoogle({
				access_token: data.accessToken,
				email: data.profileObj.email,
				first_name: data.profileObj.givenName,
				last_name: data.profileObj.familyName,
				picture: data.profileObj.imageUrl,
			});
			authStore.signInForm.modal.close();
			navigate("/cabinet/my-subscriptions");

			gtmEvent({ event: "Sign_up", email: data.profileObj.email.toLowerCase() });
		} catch {}
	};

	return (
		<CommonFormContainer onlyForm>
			<CommonFormRightSide>
				<CommonFormSocial>
					{/*<GoogleLogin
						clientId={"826618397728-phql2reajkfudj3q0uosjuqb7tpjdlhf.apps.googleusercontent.com"}
						buttonText="Login"
						onSuccess={handleOnGoogle}
						onFailure={(responseError) => console.log("responseError", responseError)}
						cookiePolicy={"single_host_origin"}
						render={(renderProps) => {
							return (
								<Button theme={ButtonTheme.GOOGLE} onClick={renderProps.onClick}>
									Sign up with Google
								</Button>
							);
						}}
					/>*/}
				</CommonFormSocial>

				<SignUpDescription style={{ height: "30px" }}>or log in with email</SignUpDescription>

				<SignUpFormContainer onSubmit={formik.handleSubmit}>
					<InputField
						type={"email"}
						name={"email"}
						value={formik.values.email}
						placeholder={"Your email"}
						errorMessage={formik.errors.email}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>

					<SignUpDescription style={{ height: "60px" }}>
						Your password will be generated & sent onto your email. You will be able to change it once you login.
					</SignUpDescription>

					<Button type={"submit"} disabled={formik.errors.email !== undefined || authStore.signUpForm.isLoading}>
						Sign Up
					</Button>
				</SignUpFormContainer>

				<SignUpRightSideFooter>
					<CommonFormDescription>Already have an account?</CommonFormDescription>
					<Button theme={ButtonTheme.LINK_LARGE} onClick={handleOnSignIn}>
						Sign In
					</Button>
				</SignUpRightSideFooter>
			</CommonFormRightSide>
		</CommonFormContainer>
	);
};
