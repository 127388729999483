import styled from "styled-components";
import { motion } from "framer-motion";
import { COLORS } from "../../../../../../../styles";

export const TempoContainer = styled.div`
	position: absolute;
	left: 0;
	top: 0px;
	width: 100%;
`;

export const TempoBaseLine = styled(motion.div)`
	display: flex;
	width: 100%;
	height: 2px;
	background: ${COLORS.Gold};
	margin-top: 74px;
`;
