import styled, { css } from "styled-components";
import { COLORS } from "../../../../styles";

interface MainContainer {
	isWhite: boolean;
}

export const MainContainer = styled.main<MainContainer>`
	grid-area: main;
	display: grid;
	grid-template-columns: 1fr;
	justify-content: center;
	background: ${COLORS.Light_Gray};
	align-items: flex-start;
	min-height: 100vh;

	${(props) =>
		props.isWhite &&
		css`
			background: ${COLORS.White};
		`}
`;
