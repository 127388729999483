import { HTMLAttributes, useEffect, useLayoutEffect } from "react";
import { useStores } from "../../stores";
import { HEADER_TYPE } from "../../constants";
import { ContainerGridBlock } from "../../components/common/ContainerGrid/ContainerGrid.styled";
import { Star } from "./Star";
import { Title } from "./Title";

type PricingActualPageProps = HTMLAttributes<HTMLElement>;

export const PricingActualPage = ({}: PricingActualPageProps) => {
	const { settingsStore } = useStores();

	// Устанавливаем темную шапку
	useLayoutEffect(() => {
		settingsStore.setTypeHeader(HEADER_TYPE.RELATIVE);
		settingsStore.setIsWhiteMain(true);
	}, []);

	useEffect(() => {
		settingsStore.setTypeHeader(HEADER_TYPE.RELATIVE);
		settingsStore.setIsWhiteMain(true);

		return () => {
			settingsStore.setIsWhiteMain(false);
		};
	}, []);

	return (
		<>
			<ContainerGridBlock width={1170}>
				<Star />
				<Title />
			</ContainerGridBlock>
		</>
	);
};
