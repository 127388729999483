import { SContainer } from "./Start.styled";
import { ReactComponent as StarSvg } from "./image/start.svg";

interface StarProps {}

export const Star = ({}: StarProps) => {
	return (
		<SContainer>
			<StarSvg />
		</SContainer>
	);
};
